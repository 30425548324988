import React from "react";

import { Link } from 'gatsby';

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Typography,
  Paper,
  ButtonBase,
} from "@material-ui/core";

import clsx from 'clsx';

import {
  SoldListingItem,
  timeAgo,
  getExteriorColorIndex,
} from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        margin: theme.spacing(1, 1),
        overflow: 'hidden',
        flex: '0 0 auto',

        '&:first-child': {
          marginLeft: 0
        },
        '&:last-child': {
          marginRight: 0
        },
      },
      content: {
        width: 300,
        height: 250,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative',
        backgroundColor: '#fff',
      },
      noPhoto: {
        backgroundSize: '94%',
      },
      top: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.40)',
        padding: theme.spacing(1),
        color: '#fff',
        textAlign: 'left',
      },
      bottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, 0.40)',
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      noPhotoText: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        color: '#333',
      }

    }
  ),
);

interface Props {
  data: SoldListingItem;
}

const SaleItemCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const exteriorColorIndex = getExteriorColorIndex(data.exteriorColor, data.trimData.exteriorColors);

  const getImgUrl = () => {
    if (data.photos.length === 0) {
      const imgUrl = data.trimData.exteriorColors[ exteriorColorIndex ].images.md ? data.trimData.exteriorColors[ exteriorColorIndex ].images.md : data.modelData?.placeholderImage;
      return process.env.CDN_BASE_URL_CARS + '/' + imgUrl || '';
    }
    else {
      return data.photos[ 0 ].src ? process.env.CDN_BASE_URL + '/' + data.photos[ 0 ].src : data.photos[ 0 ].original;
    }
  }

  return (
    <Paper className={classes.root} elevation={1}>
      <ButtonBase
        className={clsx(classes.content, { [ classes.noPhoto ]: data.photos.length === 0 })}
        style={{ backgroundImage: `url(${getImgUrl()})` }}
        component={Link}
        to={`/sold-car/${data.id}`}
      >
        <div className={clsx(classes.top, { [ classes.noPhotoText ]: data.photos.length === 0 })}>
          <Typography variant="body2">{`${data.modelData?.modelYear} ${data.carData?.name} ${data.modelData?.name}`}</Typography>
          <Typography variant="caption">{data.trimData?.name}</Typography>
        </div>
        <div className={clsx(classes.bottom, { [ classes.noPhotoText ]: data.photos.length === 0 })}>
          <Typography variant="body2">${data.monthlyPayment} monthly</Typography>
          <Typography variant="caption">{timeAgo(data.createTime as number)}</Typography>
        </div>
      </ButtonBase>
    </Paper>
  );
};

export default SaleItemCard;