import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Grid, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        backgroundColor: '#eee',
        padding: theme.spacing(10, 1, 14, 1),
      },
      featureImg: {
        maxWidth: 300,
      },
      box: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',

      }
    }
  ),
);

const HomeInfoBox: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div className={classes.box}>
              <img src="/assets/home-choose-car.svg" alt="Choose your perfect car" className={classes.featureImg} />
              <Box mt={2} textAlign="center">
                <Typography variant="h6">1. CHOOSE YOUR CAR</Typography>
                <Typography>Find and configure your brand new car with our easy to use car builder.</Typography>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.box}>
              <img src="/assets/home-compare-offers.svg" alt="Compare car offers" className={classes.featureImg} />
              <Box mt={2} textAlign="center">
                <Typography variant="h6">2. COMPARE YOUR OFFERS</Typography>
                <Typography>Get offers from dealers nearby and compare prices, location and dealer rating – no hidden costs.</Typography>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.box}>
              <img src="/assets/home-buy.svg" alt="Lease a car" className={classes.featureImg} />
              <Box mt={2} textAlign="center">
                <Typography variant="h6">3. LEASE WITH CONFIDENCE</Typography>
                <Typography>Simply contact your preferred dealer with the offer code and lease the car directly from them.</Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HomeInfoBox;