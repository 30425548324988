import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Card, CardHeader, Avatar, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        maxWidth: 320,
        margin: theme.spacing(1),
        borderRadius: 20,
      },
    }
  ),
);


interface Props {
  avatar: string;
  name: string;
  location: string;
  message: string;
}

const ReviewCard: React.FC<Props> = ({ avatar, name, location, message }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        avatar={
          <Avatar src={avatar} alt={name} />
        }
        title={name}
        subheader={location}
      />
      <CardContent>
        <Typography variant="body1">{message}</Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;