import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import NextIcon from '@material-ui/icons/NavigateNext';
import { trackEvent } from "../../analytics";
import { CarMake } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      carLogo: {
        width: 40,
        height: 40,
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
    }
  ),
);

interface Props {
  cars: CarMake[];
  onSelect: (index: number) => void;
}

const CarList: React.FC<Props> = ({ cars, onSelect }) => {
  const classes = useStyles();

  return (
    <List>
      {cars.map((car, index) => (
        <ListItem key={car.id} button onClick={() => {
          trackEvent('pickCarMake', { car: car.id });
          onSelect(index);
        }}>
          <ListItemIcon>
            <img className={classes.carLogo} src={process.env.CDN_BASE_URL_CARS + "/" + car.logoUrl} alt={car.name} />
          </ListItemIcon>
          <ListItemText primary={car.name} />
          <ListItemSecondaryAction>
            <NextIcon />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
};

export default CarList;