import React from "react"
import random from 'lodash/random';
import { makeStyles, createStyles } from "@material-ui/styles"

import { Theme, Button, Typography } from "@material-ui/core";
import Logo from "./Logo";

const backgrounds = [
  '/assets/bg/bmw-x5-1.jpg',
  '/assets/bg/bmw-x5-2.jpg',
  '/assets/bg/honda-accord.jpg',
  '/assets/bg/mb-e-class.jpg',
  '/assets/bg/mb-g63.jpg',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      '@keyframes bgReveal': {
        '100%': {
          transform: 'scale(1.2)'
        }
      },
      root: {
        position: "relative",
        height: "60vh",
        width: '100%',
        overflow: 'hidden',
        // marginBottom: -14,
      },
      bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        animationName: '$bgReveal',
        animationFillMode: 'forwards',
        animationDuration: '20s',
        animationDelay: '0.2s',
        opacity: '0.98',
        backgroundImage: `linear-gradient(top, rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${backgrounds[random(backgrounds.length - 1)]})`,
        // backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        // transition: 'background-color 1s',
      },
      front: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        color: '#fff',
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
      },
    }
  ),
);

interface Props {
  onSelectCar: () => void;
}

const HomeBanner: React.FC<Props> = ({ onSelectCar }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.bg}></div>
      <div className={classes.front}>
        <Logo variant="big" />
        <Typography paragraph>The smarter way to lease your next car</Typography>
        <Button color="secondary" variant="contained" size="large" onClick={onSelectCar} disableElevation>Pick your car</Button>
      </div>
    </div>
  )
}


export default HomeBanner;