import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar"
import TextField from "@material-ui/core/TextField"
import LocationIcon from "@material-ui/icons/MyLocation"
import Typography from "@material-ui/core/Typography"

import ButtonLoading from '../../components/ButtonLoading';

import { actions as userActions } from '../../services/user';
import izValidZip from '../../utils/isValidZip';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { trackEvent } from "../../analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(2, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
    }
  ),
);

interface Props {
  onSet: (zip: number) => void;
  loading: boolean;
}

const SetZipCode: React.FC<Props> = ({ onSet, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialZip = useSelector((state: RootState) => state.user.zipCode);

  const [ value, setValue ] = useState(initialZip ? initialZip.toString() : '');
  const [ error, setError ] = useState('');

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const zip = parseInt(value);
    if (!izValidZip(zip)) {
      setError(`${value} is not valid LA zip code`);
    }
    else {
      dispatch(userActions.saveZipCode(zip));
      trackEvent('pickCarZip');
      onSet(zip);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Container maxWidth="xs">
      <div className={classes.root}>
        <Avatar className={classes.avatar}>
          <LocationIcon />
        </Avatar>
        <Typography component="h1" variant="h5" paragraph>
          What's your ZIP code?
        </Typography>

        <Typography align="center" variant="body2">
          Currently we operate only in greater Los Angeles area. We are working hard to expand into more US cities and states.
        </Typography>
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="zip-code"
            label="Zip code"
            name="zip-code"
            autoComplete="zip"
            error={error !== ''}
            helperText={error}
            value={value}
            onChange={handleChange}
          />

          <ButtonLoading
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={loading}
          >
            Continue
          </ButtonLoading>
        </form>
      </div>
    </Container>
  );
};

export default SetZipCode;