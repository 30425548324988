import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, ButtonBase, Container } from "@material-ui/core";
import { CarModel, CarMake } from "leasemojo-common";
import { trackEvent } from "../../analytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
      },
      item: {
        margin: theme.spacing(2, 1),
        overflow: 'hidden',
        '&:hover': {
          boxShadow: theme.shadows[ 4 ]
        },
        width: 380,
        height: 220,
        [ theme.breakpoints.down('xs') ]: {
          width: 300,
          height: 178,
        },
      },
      button: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        cursor: 'pointer',
      },
      image: {
        width: '100%',
      }
    }
  ),
);

interface CarModelsProps {
  models: CarModel[],
  car: CarMake,
  onSelect: (slug: string) => void;
}

const CarModels: React.FC<CarModelsProps> = ({ models, car, onSelect }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {models.map((model, index) => (
        <Paper className={classes.item} key={`${car.name}-${model.name}`}>
          <ButtonBase className={classes.button} onClick={() => {
            trackEvent('pickCarModel', { car: car.id, model: model.slug });
            onSelect(model.slug);
          }} >
            <Typography>
              {model.name}
            </Typography>
            <Typography variant="caption">{model.modelYear}</Typography>
            <img className={classes.image} src={process.env.CDN_BASE_URL_CARS + '/' + model.imageUrl} alt={`${car.name} - ${model.modelYear} ${model.name}`} />
          </ButtonBase>
        </Paper>
      ))}
    </div>
  );
};

export default CarModels;