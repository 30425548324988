import React from "react"
import { graphql } from "gatsby"
import { makeStyles, createStyles } from "@material-ui/styles"
import Layout from "../components/Layout"
import HomeBanner from "../components/HomeBanner"

import { Theme, Typography, Button, useMediaQuery } from "@material-ui/core"
import { useTheme } from '@material-ui/core/styles';

import PickCarModal from "../components/PickCarModal/PickCarModal";

import { useQueryParams, BooleanParam, NumberParam } from 'use-query-params';
import { CarMake, CarModel, SoldListingItem } from "leasemojo-common"


import PublishedSaleCard from '../components/PublishedSaleCard';
import HomeInfoBox from '../components/HomeInfoBox';
import ReviewCard from "../components/ReviewCard";
import CarGrid from "../components/CarGrid/CarGrid";


import { trackEvent } from '../analytics';



interface Props {
  data: {
    allCar: {
      nodes: CarMake[]
    }
    allModel: {
      nodes: CarModel[]
    },
    allPublishedSale: {
      nodes: SoldListingItem[]
    },
    site: {
      siteMetadata: {
        title: string
      }
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      carListContainer: {
        backgroundColor: '#fff'
      },
      soldItems: {
        backgroundColor: '#fff',
        padding: theme.spacing(8, 2, 12, 2),
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
      },
      soldItemsList: {
        display: 'flex',
        // justifyContent: 'center',
        overflowX: 'scroll',
      },
      reviews: {
        padding: theme.spacing(2),
      },
      reviewList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }
    }
  ),
);


const IndexPage: React.FC<Props> = ({ data }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const [ params, setParams ] = useQueryParams({
    'pick-car': BooleanParam,
    step: NumberParam,
    car: NumberParam
  });


  const onCarClick = (index: number, car: CarMake) => {
    trackEvent('pickCarMake', { car: car.id });
    setParams({
      'pick-car': true,
      step: 1,
      car: index
    }, 'push')
  }

  const scrollThreshold: number = typeof window === 'undefined' ? 100 : window.innerHeight * 0.35;

  const openPickCar = () => {
    trackEvent('pickCar');
    setParams({
      "pick-car": true
    }, 'pushIn');

  }

  return (
    <Layout
      addPadding={false}
      scrollThreshold={scrollThreshold}
      appBarTransparent={true}
      actionButton={<Button onClick={openPickCar} color="secondary" disableElevation variant="contained" size={isMobile ? 'small' : 'medium'}>Pick A Car</Button>}
    >
      <HomeBanner onSelectCar={openPickCar} />
      <PickCarModal open={Boolean(params[ "pick-car" ])} onClose={() => {
        setParams({
          'pick-car': false
        }, 'replace');
      }} cars={data.allCar.nodes} models={data.allModel.nodes} />
      <HomeInfoBox />
      <div className={classes.soldItems}>
        <Typography variant="h5" align="center" paragraph>Recently leased through {data.site.siteMetadata.title}</Typography>
        <div className={classes.soldItemsList}>
          {
            data.allPublishedSale && data.allPublishedSale.nodes.map(item => (
              <PublishedSaleCard data={item} key={item.id} />
            ))
          }
        </div>
      </div>

      <div className={classes.reviews}>
        <Typography variant="h5" align="center" paragraph>Here's what people are saying</Typography>
        <div className={classes.reviewList}>
          <ReviewCard name="Mike" location="Burbank, CA" avatar="/avatars/mike.jpg" message="I have never leased a car before, but not anymore. I couldn't even think that it will be so easy and affordable." />
          <ReviewCard name="Anna" location="Pasadena, CA" avatar="/avatars/anna.jpg" message="Love LeaseMojo and love my new shiny BMW." />
          <ReviewCard name="George" location="Los Angeles, CA" avatar="/avatars/george.jpg" message="Within a couple of hours, I got 7 lease offers for a Toyota Camry, super happy." />
        </div>
      </div>

      <CarGrid cars={data.allCar.nodes} onClick={onCarClick} />

    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    allCar {
      nodes {
        id
        logoUrl
        name
        slug
      }
    }
    allModel {
      nodes {
        carId
        imageUrl
        name
        slug
        modelYear
      }
    },
    site {
      siteMetadata {
        title
      }
    },
    allPublishedSale(limit: 10, sort: {fields: createTime, order: DESC}) {
      nodes {
        id,
        createTime,
        carData {
          name
          logoUrl
          slug
        }
        modelData {
          id
          originalId
          name
          bodyStyles
          imageUrl
          modelYear
          placeholderImage
          slug
        }
        trimData {
          id
          name
          bodyStyle
          msrp
          slug
          exteriorColors {
            images {
              lg
              md
            }
            msrp
            name
            id
          }
          interiorColors {
            msrp
            name
            id
          }
          modelYear
        }
        photos {
          src
          thumb
          path
        }
        leaseLength
        downPayment
        creditScore
        monthlyPayment
        exteriorColor
        interiorColor
        mileage
      }
    }
  }
`
