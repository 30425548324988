const zips = [
  90895,
  91001,
  91006,
  91007,
  91011,
  91010,
  91016,
  91020,
  91017,
  93510,
  91023,
  91024,
  91030,
  91040,
  91043,
  91042,
  91101,
  91103,
  91105,
  93534,
  91104,
  93532,
  91107,
  93536,
  91106,
  93535,
  91108,
  93543,
  93544,
  91123,
  93551,
  93550,
  91125,
  93553,
  93552,
  91182,
  93563,
  91189,
  91202,
  91201,
  93591,
  91204,
  91203,
  91206,
  91205,
  91208,
  91207,
  91210,
  91214,
  91302,
  91301,
  91304,
  91303,
  91306,
  91307,
  91310,
  92397,
  91311,
  91316,
  91321,
  91325,
  91324,
  91326,
  91331,
  91330,
  91335,
  91340,
  91343,
  91342,
  91345,
  91344,
  91350,
  91346,
  91352,
  91351,
  91354,
  91356,
  91355,
  91357,
  91361,
  91364,
  91367,
  91365,
  91381,
  91383,
  91384,
  91387,
  91390,
  91402,
  91401,
  91404,
  91403,
  91406,
  91405,
  91411,
  91423,
  91436,
  91495,
  91501,
  91502,
  91505,
  91504,
  91506,
  91602,
  91601,
  91604,
  91606,
  91605,
  91608,
  91607,
  91614,
  91706,
  91702,
  91711,
  91722,
  91724,
  91723,
  91732,
  91731,
  91733,
  91735,
  91740,
  91741,
  91745,
  91744,
  91747,
  91746,
  91748,
  90002,
  90001,
  91750,
  91755,
  90004,
  90003,
  91754,
  90006,
  90005,
  90008,
  91759,
  90007,
  90010,
  91765,
  90012,
  90011,
  91767,
  90014,
  91766,
  90013,
  90016,
  90015,
  91768,
  90018,
  91770,
  90017,
  91773,
  90020,
  91772,
  90019,
  90022,
  91776,
  90021,
  91775,
  91780,
  90024,
  91778,
  90023,
  90026,
  90025,
  90028,
  90027,
  91790,
  91789,
  90029,
  91792,
  90032,
  90031,
  91791,
  90034,
  91793,
  90033,
  90036,
  90035,
  91801,
  90038,
  90037,
  90040,
  91803,
  90039,
  90042,
  90041,
  90044,
  90043,
  90046,
  90045,
  90048,
  90047,
  90049,
  90052,
  90056,
  90058,
  90057,
  90060,
  90059,
  90062,
  90061,
  90064,
  90063,
  90066,
  90065,
  90068,
  90067,
  90069,
  90071,
  90074,
  90077,
  91008,
  90084,
  90089,
  90095,
  90094,
  90096,
  90099,
  90201,
  90189,
  90211,
  90210,
  90212,
  90221,
  90220,
  90222,
  90230,
  90232,
  90241,
  90240,
  90245,
  90242,
  90248,
  90247,
  90250,
  90249,
  90254,
  90260,
  90255,
  90262,
  90264,
  90263,
  90266,
  90265,
  90270,
  90274,
  90272,
  90277,
  90275,
  90280,
  90278,
  90291,
  90290,
  90293,
  90292,
  90295,
  90301,
  90296,
  90303,
  90302,
  90305,
  90304,
  90402,
  90401,
  90404,
  90403,
  90406,
  93243,
  90405,
  90501,
  90503,
  90502,
  90505,
  90504,
  90508,
  90601,
  90603,
  90602,
  90605,
  90604,
  90606,
  90631,
  90639,
  90638,
  90650,
  90640,
  90660,
  90670,
  90702,
  90701,
  90704,
  90703,
  90706,
  90710,
  90713,
  90712,
  90715,
  90717,
  90716,
  90731,
  90723,
  90733,
  90732,
  90745,
  90744,
  90747,
  90746,
  90755,
  90803,
  90802,
  90805,
  90804,
  90807,
  90806,
  90808,
  90813,
  90810,
  90815,
  90814,
  90840
];

const isValidZip = (zip: number): boolean => {
  return zips.includes(zip);
}

export default isValidZip;