import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, IconButton } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import BackIcon from '@material-ui/icons/NavigateBefore';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TransitionProps } from '@material-ui/core/transitions';

import SwipeableViews from 'react-swipeable-views';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import CarList from './CarList';
import ModelList from './ModelList';
import SetZipCode from './SetZipCode';
import { CarMake, CarModel } from "leasemojo-common";

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {

      },
      content: {
        padding: 0,
      },
      carLogo: {
        width: 40,
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      backIcon: {
        color: theme.palette.grey[ 500 ],
      },
      slides: {

      },
      title: {
        padding: theme.spacing(1),
      },
    }
  ),
);

interface PickCarDialogProps {
  open: boolean;
  onClose: () => void;
  cars: CarMake[];
  models: CarModel[];
}

const PickCarModal: React.FC<PickCarDialogProps> = ({ open, onClose, cars, models }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [query, setQuery] = useQueryParams({
    step: NumberParam,
    car: NumberParam,
    model: StringParam,
    zip: NumberParam,
  });

  const step = query.step || 0;
  const car = query.car || 0;
  const model = query.model || '';
  const zip = query.zip || 0;
  
  const [ loading, setLoading ] = useState(false);

  const onSelectCar = (value: number) => {
    setQuery({
      step: step +1,
      car: value
    }, 'pushIn');
  }

  const onSelectModel = (slug: string) => {
    setQuery({
      model: slug,
      step: step +1,
    }, 'pushIn');
  }

  const onSetZipCode = (value: number) => {
    setLoading(true);
    navigate(`/build/${cars[ car ].slug}/${model}`);
  }

  const goBack = () => {
    setQuery({
      step: step - 1
    }, 'replaceIn');
  }



  const getTitle = (): string => {
    if (step === 0) {
      return 'Pick a car';
    }
    else if (step === 1) {
      return `${cars[ car ].name}`;
    }
    else if (step === 2) {
      return 'Your zip code';
    }
    return '';
  }

  const selectedModels = typeof car === 'undefined' ? [] : models.filter(model => model.carId === cars[ car ].id);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      className={classes.root}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.title} style={{ padding: step === 0 ? theme.spacing(2) : theme.spacing(1) }}>
        {step === 0 ? null : <IconButton aria-label="close" className={classes.backIcon} onClick={goBack}>
          <BackIcon />
        </IconButton>}
        {getTitle()}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <SwipeableViews index={step} disabled slideStyle={{ maxHeight: typeof window === 'undefined' ? 600 : window.innerHeight - 64 }}>
          <CarList cars={cars} onSelect={onSelectCar} />
          <ModelList models={selectedModels} car={typeof car === 'undefined' ? cars[ 0 ] : cars[ car ]} onSelect={onSelectModel} />
          <SetZipCode onSet={onSetZipCode} loading={loading} />
        </SwipeableViews>
      </DialogContent>
    </Dialog>
  );
};

export default PickCarModal;